import React, {useEffect, useState} from 'react'


export default function Calendar({firstName, lastName, phone, email, locations, selection}) {
  const [state, setState] = useState({})


  useEffect(() => {
    setState(locations.filter(X=>X.id===selection)[0])
  }, [selection])


  return (
    <div>
      { state?.name? <h2>{state.name} Appointments</h2>: <></> }

      { state?.calendar_id? 
        <iframe
          frameborder="0"
          height="1000"
          width="100%"
          src={`https://app.acuityscheduling.com/schedule.php?owner=19987768&firstName=${firstName}&lastName=${lastName}&phone=${phone}&email=${email}&calendarID=${state.calendar_id}&appointmentType[]=15477160&appointmentType[]=17541664`}
        ></iframe>
        : <></>
      }
    </div>
  )
}