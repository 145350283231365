import {API} from '../config'


/** posts to OsteoStrong server
 * @param url_fragment ~ this fragment gets prepended with the remote-host
 * @param data ~ the request body
 */
export async function post(url_fragment = '', data = {}, signal) {
  sessionStorage.setItem('loading', true)

  data = {...data, _phase:process.env.NODE_ENV}
  
  let body = JSON.stringify(data)

  const token = sessionStorage.getItem('jwt_token')

  const url = API.URL + '/' + url_fragment
  
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: `${body}`, // body data type must match "Content-Type" header
    mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json' 
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    signal: signal,
  });

  sessionStorage.setItem('loading', false)

  return await response.json(); // parses JSON response into native JavaScript objects
}


/** puts to OsteoStrong server
 * @param url_fragment ~ this fragment gets prepended with the remote-host
 * @param data ~ the request body
 */
export async function put(url_fragment = '', data = {}, signal) {
  sessionStorage.setItem('loading', true)

  data._phase = process.env.NODE_ENV
  
  let body = JSON.stringify(data)

  const token = sessionStorage.getItem('jwt_token')

  const URL = [API.URL, url_fragment].join('/')
  
  const response = await fetch(URL, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    body: `${body}`, // body data type must match "Content-Type" header
    // mode: 'no-cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    signal: signal,
  });

  sessionStorage.setItem('loading', false)

  return await response.json(); // parses JSON response into native JavaScript objects
}


export async function get(url_fragment = '') {
  sessionStorage.setItem('loading', true)

  const URL = [API.URL, url_fragment].join('/')

  // Default options are marked with *
  const response = await fetch(URL, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    // mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    headers: {
      Accept: 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('jwt_token'),
      'Content-Type': 'application/json'
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
  })

  sessionStorage.setItem('loading', false)

  return response.json(); // parses JSON response into native JavaScript objects
}