import Map from './Map'
import React, {useEffect, useState} from 'react'

import { get } from "../lib/network";


export default function Locations({locations, position, setLocations, setSelection}) {
  useEffect(() => {
    get('affiliate/locations')
    .then(loc=>{
      console.log(loc)
      setLocations(loc)
    })
    .catch(console.error)
  }, [])


  return (
    <Map
      locations={locations}
      position={position}
      setSelection={setSelection}
    />
  )
}