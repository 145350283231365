import Affiliate from "./Affiliate";
import Calendar from "./Calendar";
import Lead from "./Lead";
import Locations from "./Locations";

import { post } from "./lib/network";
import {useEffect, useState} from 'react'

import './App.css';
import banner from "./img/OSbanner_YellowWhite.png";


export default function App() {
  const [affiliateId, setAffiliateId] = useState(0)
  const [email, setEmail] = useState('')
  const [geoPosition, setGeoPosition] = useState({})
  const [locations, setLocations] = useState([])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [selection, setSelection] = useState(0)


  useEffect(() => {
    const parm = new URLSearchParams(window.location.search)

    setAffiliateId(parm.get('affiliate_id'))
    setEmail(parm.get('email'))
    setPhone(parm.get('phone'))
    setFirstName(parm.get('first_name'))
    setLastName(parm.get('last_name'))

    navigator.geolocation.getCurrentPosition(
      res=>setGeoPosition(res),
      console.error,
      {enableHighAccuracy: true}
    )
  }, [])


  useEffect(() => {
    if (affiliateId) {
      console.log(affiliateId)

      const lead = {
        affiliate_id: affiliateId,
        email: email,
        phone: phone,
        first_name: firstName,
        last_name: lastName,
      }

      console.log('lead', lead)
  
      post('affiliate/addLead', lead)
      .then(console.log)
      .catch(console.error)
    }
  }, [affiliateId])


  return (
    <>
      <header>
        <img
          alt="OsteoStrong"
          className="header image"
          src={banner}
        />
      </header>

      <Affiliate id={affiliateId} />

      <Lead
        email={email}
        firstName={firstName}
        lastName={lastName}
        phone={phone}
      />

      <Locations
        positon={geoPosition}
        locations={locations}
        setLocations={setLocations}
        setSelection={setSelection}
      />

      <Calendar
        firstName={firstName}
        lastName={lastName}
        email={email}
        locations={locations}
        selection={selection}
      />

      <footer>OsteoStrong</footer>
    </>
  );
}