import React, {useEffect, useState} from 'react'
import GoogleMapReact from 'google-map-react';
import MapMarker from "./Marker";


export default function Map({locations, position, setSelection}) {
  const [defaults, setDefaults] = useState({
    center: {
      lat: 29.8,
      lng: -95.4,
    },
    zoom: 6  
  })


  useEffect(() => {
    if (position?.coords) {
      setDefaults({
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
        zoom: 6  
      })
    }
  }, [position])


  const handleApiLoaded = (map, maps) => {
    console.log(map, maps)
  };


  return (
    <div className="map container">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyA8NvWd8p9iSY3ygefInYBd4Cde-C-_5p0' }}
        defaultCenter={defaults.center}
        defaultZoom={defaults.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
      >
        {locations.map(X=>{
          if ((X.latitude !== 0) && (X.longitude !== 0)) {
            return <MapMarker
              key={X.calendar_id}
              id={X.id}
              lat={X.latitude}
              lng={X.longitude}
              text={X.name} 
              setSelection={setSelection}
            />
          }
        } )}
      </GoogleMapReact>
    </div>
  )
}