import React from 'react'

export default function index({firstName, lastName, phone, email}) {
  return (
    <div className="lead container">
      <h2>{firstName} {lastName}</h2>

      <h3>{phone}</h3>

      <h3>{email}</h3>
    </div>
  )
}