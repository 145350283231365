import React, {useEffect, useState} from 'react'

import { get } from "../lib/network";


export default function Affiliate({id=-7}) {
  const [state, setState] = useState({
    name: ''
  })


  useEffect(() => {
    if (id) {
      get(`affiliate/${+id}`)
      .then(result=>{
        console.log('affiliate', result)
        setState(result)
      })
      .catch(console.error)
    }
  }, [id])


  return (
    <div className="affiliate container">
      <h2>{state.name}</h2>
    </div>
  )
}