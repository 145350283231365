import React from 'react'

import marker from "../img/map-marker.png";


export default function Marker({id, setSelection, text}) {
  function handleClick(event) {
    setSelection(id)
  }


  return (
    <a className="map marker" onClick={handleClick}>
      <img
        height={32}
        width={28}
        src={marker}
      />
    </a>
  )
}